


















import { Component, Vue } from "vue-property-decorator";

@Component
export default class OperatnNotFound extends Vue {

  /* GETTERS AND SETTERS */

  get requestedRoute(): string {
    return this.$route.path;
  }
}
